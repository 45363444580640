import client from "@/api/client"
import { cdnPublicFolderUrl, cdnUrl } from "@/constants"
import { useToast } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import { useUserInfoQuery } from "@/queries"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import Image from "next/image"
import Link from "next/link"
import { useState } from "react"
import IconButton from "../IconButton"
import LoadingIcon from "../LoadingIcon"
import Modal from "../Modal"
import PasscodeInput from "../Setting/Security/PasscodeInput"

const ReactivateAccountModal = () => {
  const { logout, user, userInfoQuery } = useAuth()

  const [passcode, setPasscode] = useState("")
  const toast = useToast()
  const qc = useQueryClient()

  const { mutate: mutateReactivateAccount, isPending: isLoadingReactivateAccount } = useMutation({
    mutationFn: () =>
      client.api.userControllerReactivateAccount({
        code: passcode,
      }),
    onSuccess: () => {
      toast({
        status: "success",
        title: "Account reactivated",
        message: ["Your account has been reactivated successfully."],
      })

      const userInfoKey = useUserInfoQuery.getKey()

      const userInfo = qc.getQueryData(userInfoKey)
      if (!userInfo) return
      qc.setQueryData(userInfoKey, {
        ...userInfo!,
        deletedAt: null,
      })
    },
    onSettled: () => {
      userInfoQuery?.refetch()
    },
    onError: (error: any) => {
      toast({
        status: "error",
        title: "Error",
        message: [error?.message],
      })
    },
  })

  const { mutateAsync: mutateAsyncSendCode, isPending: isLoadingSendCode } = useMutation({
    mutationFn: () => client.api.userControllerSendAccountReactivationCode(),
    onSuccess: () => {
      toast({
        status: "success",
        title: "Code sent",
        message: ["We have sent you a code to your email. Please check your inbox."],
      })
    },
  })

  const handleSendCode = async () => {
    if (!userInfoQuery?.data?.email) {
      toast({
        status: "error",
        title: "Error",
        message: ["Email not found"],
      })

      return
    }

    mutateAsyncSendCode()
  }

  if (!userInfoQuery?.data || !user) return null

  return (
    <Modal
      containerClassName="bg-blackAlpha-900"
      className="max-w-md pb-10"
      isOpen={!!userInfoQuery.data.deletedAt}
      onClose={() => {
        logout()
      }}
    >
      <div className="flex items-center justify-center flex-col w-full">
        <div className="max-w-[12rem] w-full mb-8">
          <Image
            src={`${cdnPublicFolderUrl}/logo.png`}
            alt="Ather Logo"
            width={276 * 1.5}
            height={74 * 1.5}
            quality={100}
            priority
          />
        </div>
        <h3 className="text-xl font-semibold mb-6">Reactivate account</h3>
        <div className="">
          <p className="text-atherGray-500 mb-4 text-xs">
            You currently have your account set to be deleted.
            <br />
            Would you like to reactivate your account and log in?
          </p>
          <p className="font-[400] text-[#7E7D77] text-xs mb-2">Enter the 6-digit code we sent to your email</p>
          <div className="flex flex-col h-full md:flex-row items-center">
            <PasscodeInput
              value={passcode}
              onChange={value => {
                setPasscode(value)
              }}
              className="w-8 h-8 text-xl bg-atherGray-900 rounded-none border-atherGray-800 border-b-[2px]"
            />

            <div className="mt-4 md:mt-0 md:ml-4 ">
              <IconButton
                isLoading={isLoadingSendCode}
                colorScheme="transparent"
                className="text-atherPurple-300 p-0 bg-atherGray-900 text-sm font-semibold"
                onClick={() => handleSendCode()}
              >
                Send Code
              </IconButton>
            </div>
          </div>
          <div className="mt-8 ">
            <IconButton
              className="w-full py-3"
              isLoading={isLoadingReactivateAccount || userInfoQuery.isLoading}
              disabled={passcode.length !== 6}
              onClick={() => {
                mutateReactivateAccount()
              }}
            >
              Reactivate account
            </IconButton>
          </div>

          <div className="flex justify-center items-center mt-8">
            <p className="flex items-center text-atherGray-500">
              Can not get the code?{" "}
              <Link
                onClick={() => {
                  googleAnalytics.event({
                    action: "click",
                    category: "navbar_alpha",
                    label: `mailto:hello@${cdnUrl}`,
                    value: 1,
                  })
                }}
                href={`mailto:hello@${cdnUrl}`}
                target="_blank"
                className="flex items-center text-atherGray-0 font-semibold ml-1"
                rel="noopener noreferrer"
              >
                Contact us
              </Link>
            </p>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ReactivateAccountModal
